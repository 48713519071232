.col-w-20 {
  width: 20% !important;
  transition-property: transform width text;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}
.color-white{
  color: #ffffff;
}
.wrapper__sidebar.bg-purple svg {
  color: white;
}
.col-w-80 {
  width: 80% !important;
  transition-property: transform width text;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}
.col-w-6 {
  width: 70px !important;
  transition-property: transform width text;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}
.col-w-94 {
  width: calc(100% - 70px) !important;
  transition-property: transform width text;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}
.sidebar__heading {
  font-size: 1rem;
  font-weight: 500;
}
.sidebar__link {
  text-decoration: none;
  color: #602a94;
}
.wrapper__sidebar svg {
  font-size: 26px;
  margin-left: 10px;
}

.sidebar__link.active .wrapper__sidebar {
  color: #602a94;
  background-color: #ead3ff;
  min-height: 45px;
  min-width: 45px;
  border-radius: 10px;
}
.sidebar__link.active .wrapper__sidebar.wrapper__sidebar_hide {
  color: #602a94;
  background-color: #ead3ff;
  min-height: 40px;
  min-width: 40px;
  border-radius: 10px;
}
.sidebar__link.active .wrapper__sidebar h6 {
  color: #602a94;
  font-weight: 500;
}

.sidebar__link.active .wrapper__sidebar svg {
  color: #602a94;
}

.wrapper__sidebar {
  transition-property: font-size transform color;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}

.wrapper__sidebar:hover {
  color: #602a94;
  font-weight: 600;
  /* transform: translate(1.1); */
  /* transform: scale(1.05); */
}

.wrapper__sidebar.wrapper__sidebar_hide {
  border-radius: 10px !important;
}
.wrapper__sidebar.wrapper__sidebar_hide  svg{
  margin: auto ;
  font-size: 24px;
}

.small-logo {
  width: 55px !important;
}

.position-end{
  justify-self: end;
}

.min-height-sidebar{
  min-height: calc(100vh - 170px);
}
