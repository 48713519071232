.profile-card.profile-card-dark {
  /* background-image: url("../../assets/image/supportbg.png");
    background-size: cover;
    background-position: center; */
  background-color: #602a94;
  color: #ffffff;
}

.logo-box {
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  margin: 10px 15px;
  padding: 25px 0px;
  border-radius: 5px;
}

.support-contact {
  margin: 20px 15px;
}
.support-contact a {
  color: #ffffff !important;
}
.support-contact a p{
  padding-left: 10px !important;
}

.social-media {
  display: flex;
  gap: 15px;
  align-items: center;
}

.search-box.form-control,
.search-box.form-input {
  border-color: #ffa303 !important;
  height: 60px;
}

.search-box-flex {
  display: flex;
  justify-content: space-between;
}

.help-box {
  border: 1px solid #ffa303;
  background: #ffffff;
  display: flex;
  align-items: center;
  width: 23%;

  border-radius: 5px;
  flex-direction: column;
}

.help-box .description {
  text-align: center;
}

.help-box-cards {
  display: flex;
  gap: 2.6%;
  row-gap: 30px;
  flex-wrap: wrap;
}
