.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.btn {
  border: 2px solid #ffa303;
  color: #521986;
  background-color: transparent;
  padding: 8px 10px;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 500;
}
.btn-2 {
  border: 1px solid #521986;
  color: #521986;
  background-color: transparent;
  padding: 8px 10px;
  height: 65px;
  width: 100%;
  border-radius: 10px;
}

.upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
