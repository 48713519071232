hr {
  background-color: #602a94;
  color: #602a94 !important;
  opacity: 0.7 !important;
}

.student-card {
  box-shadow: 0px 3.76946px 6.28243px 2.51297px rgba(0, 0, 0, 0.15),
    0px 1.25649px 1.88473px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  width: 32%;
}

.student-card img {
  width: 100%;
}

.student-card h5 {
  font-weight: 550;
  font-size: 1.3rem;
}
.student-card p {
  font-weight: 550;
  font-size: 0.8rem;
}

.student-card .copy-icon svg {
  font-size: 30px;
  color: #602a94;
}

.candidates-box {
  display: flex;
  gap: 2%;
  row-gap: 20px;
  flex-wrap: wrap;
}

.checkbox {
  color: #ffa303 !important;
}
.form-check-input{
    height: 24px;
    width: 24px;
}
.form-check-input:focus {
  box-shadow: none !important;
}

.student-card svg{
    color: #602a94;
    font-size: 20px;
}

.checkbox .form-check-input:checked {
  background-color: #ffa303 !important;
}
