

.rcard .rcard-img{
    height: 70px ;
    /* width: 140px; */
}
.rcard .card-description{
    font-size: .9rem;
    line-height: 1.2rem;
}

.rcard:hover{
    background-color: #ead3ff;
}

.Recommended-vbox{
    max-height: 60vh;
    overflow-y: auto;  
}

.Recommended-vbox::-webkit-scrollbar {
    width: 2px;
    height: 100px;
    background: #c1995497
  }
  .Recommended-vbox::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background: #612a94ad
  }