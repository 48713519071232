html,
body,
.intro {
  height: 400px;
}

table td,
table th {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
tbody tr {
  background: #f9ecff;
  border-radius: 10px;
}
.css-1e2bxag-MuiDataGrid-root .MuiDataGrid-row:hover, .css-1e2bxag-MuiDataGrid-root .MuiDataGrid-row.Mui-hovered {
  background: #f9ecff !important;
}
.css-1e2bxag-MuiDataGrid-root .MuiDataGrid-row.Mui-selected {
  background: #f9ecff !important;

}
.css-iclwpf .MuiDataGrid-row.Mui-selected:hover, .css-iclwpf .MuiDataGrid-row.Mui-selected.Mui-hovered {
  background-color: #f9ecff !important;
}

.css-iclwpf .MuiDataGrid-row.Mui-selected {
  background-color:#f9ecff !important;
}
.css-iclwpf .MuiDataGrid-row:hover, .css-iclwpf .MuiDataGrid-row.Mui-hovered {
  background-color:#f9ecff !important;
}
.css-1e2bxag-MuiDataGrid-root .MuiDataGrid-columnHeader:focus, .css-1e2bxag-MuiDataGrid-root .MuiDataGrid-cell:focus {
  outline: none !important;
}
.css-iclwpf .MuiDataGrid-columnHeader:focus, .css-iclwpf .MuiDataGrid-cell:focus {
  outline: none !important;
}
.css-fww4oi .css-1e2bxag-MuiDataGrid-root .MuiDataGrid-row {
 
  cursor: auto !important;
}
thead {
  background-color: #7944ae;
  color: #fff;
  padding: 20px 0;
  border-radius: 10px 10px 0px 0px;
}

.card {
  border-radius: 0.5rem;
}

.table-scroll {
  border-radius: 0.5rem;
}

.table-scroll table thead th {
  font-size: 1.25rem;
}
thead {
  top: 0;
  position: sticky;
}
.css-dpoqzr {
  margin: 0px;
  width: 100% !important;
}