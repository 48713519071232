.video-cam-box {
  overflow: hidden;
  width: 100%;
  /* border: 2px solid #ffa303 ; */
  border-radius: 20px;
}

.video-cam-box.width-set {
  width: inherit;
}

.interview-form .purple {
  color: #521986;
}

.mt-150px {
  margin-top: 150px;
}

.vertical-row-purple {
  width: 1px;
  background-color: #51198675 !important;
  height: 100% !important;
}

.green {
  color: #219653;
}

.circle-green {
  background-color: #ffffff;
  border: 1px solid #219653;
  border-radius: 50%;
  padding: 13px;
  width: 50px;
  height: 50px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.review-star svg {
  font-size: 28px;
  color: #ffa303;
  margin-right: 5px;
}

.feedback-input {
  border: 2px solid #ffa303;
  border-radius: 10px;
  padding: 7px;
}

.lh-20 {
  line-height: 20px !important;
}

.color-red{
  color: red !important;
}