.nav-head {
  font-size: 1.1rem;
  font-weight: 600;
  margin: 0px;
}
.nav-para {
  font-size: 0.8rem;
  font-weight: 400;
  display: flex;
  align-items: center;
  padding-left: 5px;
  margin: 0px;
}

.nav-level {
  display: flex;
  align-items: center;
  background-color: #521986;
  border-radius: 6px;
  padding: 8px 10px 7px 10px;
  margin: 0px 6px;
}
.renew {
  display: flex;
  align-items: center;
  border-radius: 6px;
  background-color: #521986;

  padding: 9px 12px 9px 12px;
  margin: 0px 6px;
}
.renew.bg-purple {
  display: flex;
  align-items: center;
  border-radius: 6px;
  color: #521986;
  background-color: #ffffff;
  padding: 9px 12px 9px 12px;
  margin: 0px 6px;
}
.renew.renew.bg-purple h6 {
  color: #521986;
}
.nav-notification.bg-purple svg {
  color: #ffffff;
}
.nav-level h6,
.renew h6 {
  margin: 0px;
  font-size: 0.9rem;
  color: #ffffff;
}
.nav-notification {
  margin: 0px 5px;
  color: #521986;

  transition-property: font-size transform color;
  padding: 8px;
  border-radius: 50%;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  cursor: pointer;
}

.nav-notification:hover {
  color: #521986;
  background-color: #e2cdf9;
}
.nav-notification.active {
  color: #521986;
  background-color: #ffffff;
  box-shadow: 0px 6px 10px 4px rgb(0 0 0 / 15%), 0px 2px 3px rgb(0 0 0 / 30%);
}
.nav-notification.active svg {
  color: #521986;
}

.user-img-cover {
  margin-left: 6px;
  width: 45px;
  height: 45px;
}

.user-img {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  border: 2px solid #ffa303;
}

.nav-sec-1 {
  width: 73%;
}
.nav-sec-2 {
  width: 27%;
}

.profileItem {
  position: relative;
  cursor: pointer;
  display: inline-block;
}

.profileList {
  position: absolute;
  display: none;
  width: 150px;
  background-color: white;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.33);
  border-radius: 5px;
}
.profileList2 {
  /* position: absolute;
  display: none; */
  width: 150px;
  background-color: white;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.33);
  border-radius: 5px;
}
.profileItem:hover .profileList {
  display: block;
}

.profileList ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.profileList2 ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.profileList ul > li {
  padding: 10px 10px;
  cursor: pointer;
}

.profileList2 ul > li {
  padding: 10px 10px;
  cursor: pointer;
  color: black;
}

.profileList li:hover {
  background-color: #ffa303;
  color: white;
}

.profileList2 li:hover {
  background-color: #ffa303;
  color: white;
}

.dot-not {
  height: 5px;
  width: 5px;
  color: red;
  border-radius: 50%;
  position: relative;
  top: -28px;
  left: 20px;
  background-color: red;
}
@media (max-width: 500px) {
  .hidedash {
    display: none; /* Hide the link when the screen width is less than 500px */
  }
}
