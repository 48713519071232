.job-card{
    display: flex;
    align-items: center;
}

.vertical-row{
    width: 1px;
    height: 60px;
    background-color: #ffa303;
}

.yellow ,.read-more{
    color: #ffa303;
}

.job-card h5{
    font-size: .9rem;
    font-weight: 600;
    color: #602a94;
}
.job-card p{
    font-size: .8rem;
    font-weight: 550;
margin: 0;
}

span.green-dot{
    width: 40px ;
    height: 40px;
    background-color: green;
}

.job-card-sec5{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #602a94;

}

.job-card-sec5 svg {
    font-size: 20px;
}

.c-pointer{
    cursor: pointer;
}

.analytics-drop{
    height: 40px;
  border-radius: 5px;
  padding: 5px 10px;
  border: 2px solid #ffa303;
  color: #000000;
}