.interview-score-card {
   display: flex;
   width: 280px;
   align-items: center;
   justify-content: space-around;
   border: 1px solid #ffa303;
   border-radius: 10px;
   margin: 7px;
   padding: 10px 3px;
 }
 
 .candidate-details h6 {
   color: #521986;
 }
 .candidate-details p {
   margin: 0;
   width: max-content;
   background: #c994ff;
   padding: 4px 20px;
   border-radius: 20px;
 }
 
 .candidate-image img {
   width: 70px;
   border-radius: 50%;
   border: 5px solid rgba(192, 123, 255, 1);
 }
 