.profile-card{
    background-color: #F0DBFF;
    padding: 20px 10px;
    border-radius: 20px;
    box-shadow: 0px 6px 10px 4px rgb(0 0 0 / 15%), 0px 2px 3px rgb(0 0 0 / 30%);
    width: 48% !important;
}
.profile-card-2{
    width: 48% !important;
    padding: 20px 10px;

}
.profile-sec-1{
    display: flex;
    justify-content: space-between;
}

.form-title{
    color: #602a94;
    font-size: .9rem;
    font-weight: 500;
}

.form-input{
    color: #602a94 !important;
    font-size: .9rem;
    height: 45px;
    line-height: 31px !important;
    border-color: #602a94 !important;
}

.form-control:focus,.form-select:focus{
    box-shadow: 0px 6px 10px 4px rgb(0 0 0 / 15%), 0px 2px 3px rgb(0 0 0 / 30%) !important;
}