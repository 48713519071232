.recommended-vcard {
  width: 380px;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0px 6px 10px 4px rgb(0 0 0 / 15%), 0px 2px 3px rgb(0 0 0 / 30%);
}

.vcard-sec-1 img {
  width: 100%;
  height: auto;
}

.vcard-desc div {
  display: flex;
  align-items: center;
}

.vcard-desc div p {
  margin: 0;
  font-size: 0.8rem;
  color: #000;
  font-weight: 500;
  padding: 0px 13px 0px 3px;
}
.vcard-sec-2 {
  padding: 15px 15px;
}
.vcard-sec-3 {
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
}

.vcard-sec-3 .people-watched {
  display: flex;
  align-items: center;
}
.vcard-sec-3 .people-watched .people-icon {
  background-color: #ffddb8;
  padding: 5px;
  font-size: 16px;
  border-radius: 50%;
}

.vcard-sec-2 .vcard-title {
  color: #521986;
  font-weight: 550;
  font-size: 1.1rem;
  padding: 7px 0px;
}

.people-watched h6 {
  font-size: 0.9rem;
}

.vcard-sec-3 .people-watched h6 {
  margin: 0;
  padding-left: 7px;
}

.vcard-sec-3 .vcard-more {
  display: flex;
  align-items: center;
  gap: 15px;
}

.vcard-sec-3 .vcard-more p {
  margin: 0;
  font-size: 0.8rem;
  color: #521986;
}
.vcard-sec-1 video {
  width: 380px;
}
