.header {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3),
    0px 2px 6px 2px rgba(0, 0, 0, 0.15);
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  font-size: 1.1rem;
  color: #ffffff;
  background-color: #521986;
}
