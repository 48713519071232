.dashboard-card1 {
   display: flex;
   border: 1px solid #ffa303;
   border-radius: 10px;
   padding: 7px;
   align-items: center;
   justify-content: space-evenly;
   width: 23%;
   height: 80px;
   background: #ffffff;
   transition: all ease 0.7s;
   cursor: pointer;
 }
 .dashboard-card1.bg-gradien{
   background: linear-gradient(180deg, #ff8f00 0%, #ffc93f 100%);
 }
 .dashboard-card1 h6 {
   color: #000;
 }
 
 .dashboard-card1:hover {
   background: linear-gradient(180deg, #ff8f00 0%, #ffc93f 100%);
   /* border: 1px solid #602a94; */
   transform: scale(1.05);
 }
 
 .card1-container {
   display: flex;
   flex-wrap: wrap;
   gap: 20px;
   justify-content: center;
 }
 .dashboard-card1-slider {
   width: calc(46% + 20px);
   background: linear-gradient(180deg, #ff8f00 0%, #ffc93f 100%);
   border-radius: 10px;
 }
 .dashboard-card1-slider.w-full {
   width: calc(92% + 60px);
 }
 
 .dashboard-card-slider {
   display: flex;
   padding: 0px 20px;
   align-items: center;
   justify-content: space-around;
   height: 100px;
 }
 
 .dashboard-card1 .slider-icon {
   background-color: white !important;
   padding: 3px;
   height: 40px;
   width: 40px !important;
   border-radius: 50%;
   display: flex;
   align-items: center;
   justify-content: center;
 }
 
 .dashboard-sec-2 {
   display: flex;
   gap: 20px;
   flex-wrap: wrap;
   justify-content: center;
 }
 .dashboard-sec-2-1 {
   width: calc(69% + 40px);
 }
 .dashboard-sec-2-1.w-full {
   width: calc(92% + 60px);
 }
 .dashboard-sec-2-2 {
   width: 23%;
 }
 .overview-video,
 .recommended-video,
 .reminders {
   background-color: #ffffff;
   border-radius: 10px;
 }
 .overview-video,
 .reminders {
   margin: 20px 0px;
 }
 
 .heading-dash {
   color: #ffa303;
   padding: 15px;
 }
 
 .reminder-msg-box {
   display: flex;
   align-items: center;
   justify-content: space-around;
   border: 1px solid #ffa303;
   border-radius: 10px;
   margin: 7px;
   padding: 10px 3px;
 }
 
 .reminder-msg-box h6,
 .reminder-msg-box p {
   margin: 0px;
 }
 
 .reminder-msg-box .reminder-icon {
   background-color: #f0dbff;
   border-radius: 5px;
   width: 40px;
   height: 40px;
   display: flex;
   align-items: center;
   justify-content: center;
 }
 .reminder-box-scroll{
   min-height: 400px;
   overflow-y: auto;
 }
 
 .reminder-box-scroll::-webkit-scrollbar {
   width: 2px;
   height: 100px;
   background: #c1995497
 }
 .reminder-box-scroll::-webkit-scrollbar-thumb {
   border-radius: 3px;
   background: #612a94ad
 }
 .remainder-content h6 {
   font-size: 0.8rem;
 }
 .remainder-content p {
   font-size: 0.6rem;
 }
 .remainder-time {
   color: #ae78e5;
   font-size: 0.7rem;
 }
 
 .swiper-slide {
   width: auto !important;
 }
 
 .arrow-cover {
   border: 1px solid #ffa303;
   border-radius: 50%;
   cursor: pointer;
   padding: 5px 9px;
 }
 
 .reminder-icon svg{
   font-size: 22px;
 }
 
 .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
     bottom: -4px !important;
 }