hr{
    background-color:#602a94;
    color:#602a94 !important;
    opacity: .7 !important;
}

.candidate-card{
    border: 1px solid #894ec1c1;
    border-radius: 10px;
    width: 32%;
}

.candidate-card img{
    width: 100%;
}

.candidate-card h5{
    font-weight: 550;
    font-size: 1.3rem;
}
.candidate-card p{
    font-weight: 550;
    font-size: .8rem;
}

.candidate-card .copy-icon svg{
    font-size: 30px;
    color: #602a94;
}

.candidates-box{
    display: flex;
    gap: 2%;
    row-gap: 20px ;
    flex-wrap: wrap;
}