.App {
  text-align: center;
}
* {
  font-family: sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
html {
  scroll-behavior: smooth;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.br-50 {
  border-radius: 50%;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.main-video video {
  border-radius: 20px;
}
.cursor-pointer {
  cursor: pointer;
}

.border-10-yellow {
  border: 3px solid #ffa303;
  border-radius: 10px;
}

.overflow .steps:not(:last-child) {
  overflow: hidden;
}
.fs-14 {
  font-size: 14px;
}
.fs-12 {
  font-size: 12px;
  font-weight: 400;
}

.last::after {
  top: 0 !important;
  height: 0 !important;
}

.overflow-hidden {
  overflow: hidden;
}

.flex-flow {
  flex-flow: row wrap;
}

/* Create interview */

.create-interview-image {
  height: 50%;
}
.form-check-input:checked[type="checkbox"] {
  background-color: #ffa303 !important;
}

.card-title-2 {
  font-size: 18px;
  color: black;
  font-weight: 700;
  font-family: "Inter", sans-serif;
}

.card-description-2 {
  font-size: 12px !important;
  height: 80px;
  text-overflow: ellipsis;
}

.secondary {
  color: #521986;
  font-weight: 500;
}
/* progress {
  background-color: white !important;
} */
progress::-webkit-progress-bar {
  background-color: white !important;
}
progress::-moz-progress-bar {
  background-color: white !important;
}

.css-1hv8oq8-MuiStepLabel-label {
  font-size: 20px !important;
}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  color: #ffa303 !important;
}

.overflow-scroll-steps {
  overflow-y: auto;
}

.overflow-scroll-steps::-webkit-scrollbar {
  width: 3px;
  border-radius: 5px;
  height: 100px;
  background: transparent;
}
.overflow-scroll-steps::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background: #521986;
}
.bg-purple-sidebar {
  background: #521986;
  color: #ffffff;
}
.offcanvas-header {
  height: 70px !important;
}

.text-hover {
  cursor: pointer !important;
}
.d-flex-968 {
  display: none;
}
.d-flex-820 {
  display: none;
}
.d-flex-525 {
  display: none;
}
.d-flex-768 {
  display: none;
}

.search-icon {
  color: #ffa303;
  background-color: #521986;
  padding: 4px;
  margin-right: 5px;
  cursor: pointer;
  border-radius: 50%;
}
button:disabled,
button[disabled] {
  cursor: not-allowed;
  opacity: 0.7;
  background: gray;
}

@media (max-width: 968px) {
  .d-none-968 {
    display: none;
  }
  .w-100-968 {
    width: 100% !important;
  }
  .d-flex-968 {
    display: flex;
  }
  .sidebar-968 {
    width: 275px !important;
  }
  .interview-form .video-page-2 .main-video video {
    height: 300px !important;
  }
  .interview-form .video-page-2 .video-player .player {
    height: 300px !important;
    width: 300px !important;
  }
  .submit-page .player video {
    height: 350px !important;
  }
  .interview-form.apply-interview .sc-bYMpWt.bQdGje.px-5 {
    padding: 0 !important;
  }
  .col-8-5 {
    width: 55% !important;
  }
  .col-3-5 {
    width: 43% !important;
  }
}
@media (max-width: 820px) {
  .d-none-820 {
    display: none;
  }
  .d-flex-820 {
    display: flex;
  }
  .w-100-820 {
    width: 100% !important;
  }
  .nav-sec-2 {
    width: 50%;
  }
  .dashboard-sec-2-1.w-full {
    width: 100%;
  }
  .submit-page .section-1 .player#Jol-container {
    height: 325px !important;
  }
  .submit-page .section-1 .player video {
    height: 325px !important;
  }
  .dashboard-card1-slider {
    width: 100%;
  }
  .col-8-5 {
    width: 48% !important;
  }
  .col-3-5 {
    width: 50% !important;
  }
  .student-card {
    width: 48%;
  }
}

@media (max-width: 782px) {
  .dashboard-card1 {
    width: 48.5%;
  }
  .dashboard-sec-2-1 {
    width: 100%;
  }
  .interview-form .video-cam video {
    width: 425px;
    height: 300px;
  }
  .interview-form .video-page-2 .main-video video {
    height: 250px !important;
  }
  .interview-form .video-page-2 .video-player .player {
    height: 250px !important;
    width: 250px !important;
  }
  .page-box.px-5 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}
@media (max-width: 768px) {
  .submit-page .section-1.d-flex.w-100 .box-flex {
    flex-direction: column !important;
  }
  .d-flex-768 {
    display: flex;
  }
  .d-none-768 {
    display: none !important;
  }
  .interview-form.apply-interview .section-2 {
    flex-direction: column !important;
    gap: 10px;
  }
  .interview-form.apply-interview .section-2 .margin-res {
    margin: 0 20px !important;
  }
  .flex-wrap-768 {
    flex-wrap: wrap;
  }
  .profile-card-2 {
    padding: 20px 10px;
    width: 100% !important;
  }
  .position-unset-768 {
    position: unset !important;
  }
  .col-8-5 {
    display: none !important;
  }
  .col-3-5 {
    width: 70% !important;
    margin: auto !important;
  }
}
@media (max-width: 692px) {
  .dashboard-card1 {
    width: 48%;
  }
  .dashboard-sec-2-1 {
    width: 100%;
  }
  .instruction-box.px-5,
  .interview-box.p-5 {
    padding: 15px !important;
  }
  .step-counter {
    width: 440px;
  }
  .interview-form h5 {
    font-size: 1rem;
    line-height: 1.3rem;
    margin-bottom: 0;
  }
  .interview-form h6,
  .interview-form ul li {
    font-size: 0.9rem;
    line-height: 1.1rem;
  }
  .interview-form .video-cam video {
    width: 400px;
    height: 300px;
  }
  .interview-form .prep-time {
    width: -webkit-fill-available !important;
  }
  .interview-form .video-page-2 .main-video video {
    height: 225px !important;
    width: 300px !important;
  }
  .interview-form .video-page-2 .video-player .player {
    height: 225px !important;
    width: 225px !important;
  }
  .interview-form .submit-page .video-player .player {
    height: 275px !important;
  }
  .submit-page .section-1 .player video {
    height: 275px !important;
  }
  .profile-card {
    width: 100% !important;
    margin-bottom: 20px;
  }
  .profile-page .padding-res {
    padding: 12px !important;
  }
  .que-des {
    font-size: 1.1rem;
  }
}
@media (max-width: 525px) {
  .nav-sec-2 {
    width: 70%;
  }
  .dashboard-card1 {
    width: 46%;
  }
  .card1-head {
    font-size: 0.9rem;
    line-height: 1rem;
  }
  .d-none-525 {
    display: none;
  }
  .d-flex-525 {
    display: flex;
  }
  .video-player.dashboard {
    padding: 0px !important;
    margin: 0px !important;
  }
  .video-player.dashboard .JoL-player-container {
    height: 280px !important;
  }
  .overview-video {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .instruction-box.px-5,
  .interview-box.p-5 {
    padding: 8px !important;
  }
  .section1.p-3 {
    padding: 5px !important;
  }
  .step-counter {
    width: 70%;
  }
  .section-2.px-3 {
    padding: 9px !important;
  }
  #maincircle {
    width: 25px;
    height: 25px;
    font-size: 16px;
  }
  .section-1.p-3 {
    padding: 0 !important;
  }
  .sc-pyfCe.hiYNoP {
    height: 40px;
  }
  .sc-iBYQkv.UKyVx {
    height: 40px;
  }
  .interview-form .video-cam video {
    width: 100%;
    height: 240px;
  }
  .interview-form .px-5 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .sc-iBYQkv.gdKKOR {
    height: 40px !important;
  }
  .interview-form .video-player .player {
    width: 300px !important;
    height: 200px !important;
  }
  .interview-form .section-1 button {
    width: 300px !important;
  }
  .footer {
    font-size: 0.9rem;
  }
  .interview-form .video-page-2 .video-box {
    display: flex;
    flex-direction: column;
  }
  .interview-form .video-page-2 .video-res {
    width: 100% !important;
  }
  .interview-form .video-page-2 .main-video video {
    height: 240px !important;
    width: 90% !important;
  }
  .interview-form .video-page-2 .video-player .player {
    height: 250px !important;
    width: 250px !important;
  }
  .justify-content-end.content-center {
    justify-content: center !important;
  }
  .interview-form .video-page-2.px-3 {
    padding: 0 !important;
  }
  .interview-form .video-page-2 .button-box {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: auto;
  }
  .interview-form .video-page-2 .button-box button {
    width: 225px !important;
  }
  .sc-iBYQkv.iOnTkr {
    height: 40px;
  }
  .interview-form .submit-page .video-player .player {
    height: 225px !important;
  }
  .submit-page .section-1 .player video {
    height: 225px !important;
    width: 100% !important;
  }
  .submit-page.px-3 {
    padding: 0 !important;
  }
  .interview-form.apply-interview .section-2 .margin-res {
    margin: 0 10px !important;
  }
  .interview-form.apply-interview .section-2 img {
    height: 80px !important;
    width: 80px !important;
    align-self: center;
  }
  .interview-form.apply-interview .dark-purple-bg.p-3 {
    padding: 10px !important;
  }
  .recommended-vcard {
    width: 97%;
    margin: auto;
  }
  .w-100-525 {
    width: 100% !important;
  }
  .interview-card .interview-logo img {
    width: 50px;
    height: 50px;
  }
  .interview-card .skills-cont {
    flex-direction: column;
  }
  .practice-btn-interview {
    height: 40px !important;
    width: 100% !important;
    margin-top: 10px;
  }
  .profile-page .image-box-res {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .profile-page {
    padding: 0 !important;
  }
  .p-0-525 {
    padding: 0 !important;
  }
  .interview-logo img {
    width: 50px !important;
    height: 50px !important;
  }
  .sc-gKPRtg.jNniBg {
    height: 40px !important;
  }
  .col-3-5 {
    width: 92% !important;
    margin: auto !important;
  }
  .course-btn button {
    font-size: 0.9rem !important;
  }
  .course-btn button.active {
    font-size: 0.9rem !important;
  }
  .jPEaoQ {
    padding: 0 !important;
  }
  .student-card {
    width: 100%;
  }
  .jNniBg.inactive {
    font-size: 0.9rem !important;
  }
  .sc-ipEyDJ.jwztbB,
  .biwLPT,
  .sc-ipEyDJ {
    font-size: 1.1rem !important;
  }
  .interview-card h2 {
    font-size: 1rem !important;
    line-height: 1.2rem !important;
    margin-bottom: 0;
  }
  .zirIE.inactive {
    font-size: 0.9rem !important;
  }
  .course-btn button.inactive {
    font-size: 0.9rem !important;
  }
}

@media (max-width: 360px) {
  .dashboard-card1.bg-gradien {
    width: 98%;
  }
}
