.dashboard-card1{
    display: flex;
    border: 1px solid #ffa303;
    border-radius: 10px;
    padding: 7px;
    align-items: center;
    justify-content: space-evenly;
    width: 23%;
    height: 80px;
    background-color: #ffffff;
}

.card1-container{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}
.dashboard-card1-slider{
    width: calc( 46% + 20px);
    background: linear-gradient(180deg, #FF8F00 0%, #FFC93F 100%);
    border-radius: 10px;
}

.dashboard-card-slider{
    display: flex;
    padding: 0px 20px;
    align-items: center;
    justify-content: space-around;
    height: 100px;
}

.slider-icon{
    background-color: white;
    padding: 3px;
    height: 40px;
    width: 40px !important;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dashboard-sec-2{
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
}
.dashboard-sec-2-1{
    width: calc(69% + 40px);
}
.dashboard-sec-2-2{
    width: 23%;
}
.overview-video,.recommended-video,.reminders{
    background-color: #ffffff;
    border-radius: 10px;
}
.overview-video,.reminders{
    margin: 20px 0px;
}

.heading-dash{
    color: #ffa303;
    padding: 15px;
}

.reminder-msg-box{
    display: flex;
    align-items: center;
    justify-content: space-around; 
    border: 1px solid #ffa303;
    border-radius: 10px;
    margin: 7px;
    padding: 10px 3px;
}

.reminder-msg-box h6 ,.reminder-msg-box p{
    margin: 0px;
}

.reminder-msg-box .reminder-icon{
    background-color: #F0DBFF;
    border-radius: 5px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.remainder-content h6{
    font-size: .8rem;
}
.remainder-content p{
    font-size: .6rem;
}
.remainder-time{
    color: #AE78E5;
    font-size: .7rem;
}

.swiper-slide{
    width: auto !important;
}

.min-vh-65{
    min-height: 65vh;
}