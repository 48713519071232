.notification-box{
   display: flex;
   align-items: center;
   justify-content: space-around;
    
}

.notification-border{
   border: 1px solid #ffa303;
   border-radius: 10px;
   margin: 7px;
   padding: 10px 3px;
}

.notification-box h6 ,.notification-box p{
   margin: 0px;
}

.notification-box .reminder-icon{
   background-color: #F0DBFF;
   border-radius: 5px;
   width: 40px;
   height: 40px;
   display: flex;
   align-items: center;
   justify-content: center;
}