/* card css */

.card-image-wrapper {
    position: relative;
    width: 100%;
    height: 200px;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease-in-out;
  }
  .card-content-wrapper h2 {
    font-size: 1.7rem;
    font-family: "Inter", sans-serif;
    line-height: 30px;
    color: #000;
    font-weight: 700;
  }
  .card-content-wrapper p {
    font-size: 0.9rem;
    font-family: "Inter", sans-serif;
    line-height: 25px;
    font-weight: 400;
    font-weight: 500;
    color: #777;
  }
  .card-image-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.3s ease-in-out;
  }
  
  .card-footer-right img {
    width: 20px;
    height: 20px;
  }
  
  .border-bottom1 {
    border-bottom: 0.4mm solid #7944ae;
  }
  
  .button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
  }
  .button-yellow {
    background-color: #ffa303;
    color: #fff;
    border: 1px solid #ffa303;
    border-radius: 5px;
    font-size: 1rem;
    padding: 8px 15px;
    font-family: "Inter", sans-serif;
    font-weight: 400;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
  
  .button-yellow:hover {
    background-color: #fff;
    color: #ffa303;
  }
  .button-yellow span {
    font-weight: 400 !important;
    font-size: 16px;
    margin-left: 5px;
    margin-right: 5px;
  }
  
  .card-title {
    color: #000;
  }
  
  .active-mygoal {
    background-color: #fff;
  }
  
  .border-top-mygoal {
    border-top: 1px solid #602a94;
  }
  .border-bottom-mygoal {
    border-bottom: 1px solid #602a94;
  }
  
  .interview-des p{
    margin-bottom: 0;
  }

  .search-box-border{
    border: 2px solid #ffa303;
  }